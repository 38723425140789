import React, { useState } from "react";
import groupImg from "images/group.png";

const Tab = () => {
  const [isVisionInView, setIsVisionInView] = useState(true);
  return (
    <>
      <div className="flex justify-center">
        <div className=" flex justify-center bg-gray-200 px-2 py-2 rounded-xl w-min">
          <div className="">
            <button
              className={` mx-3 font-bold ${
                isVisionInView
                  ? "bg-primary text-white rounded-xl mx-3 px-10 py-3"
                  : "px-2 py-3"
              }`}
              onClick={() => {
                setIsVisionInView(true);
              }}
            >
              Vision
            </button>
          </div>
          <div className="">
            <button
              className={` mx-3 font-bold ${
                !isVisionInView
                  ? "bg-primary text-white rounded-xl mx-3 px-10 py-3"
                  : "px-2 py-3"
              }`}
              onClick={() => {
                setIsVisionInView(false);
              }}
            >
              Mission
            </button>
          </div>
        </div>
      </div>
      {isVisionInView && (
        <div className=" grid md:grid-cols-4 grid-cols-1 md:gap-24 gap-10  mt-16">
          <div className="md:col-span-2 col-span-1">
            <p className="text-gray-700">
              In due time, we hope technological skills would be at the
              grassroots in Africa.
            </p>
            <p className="text-gray-700 mt-8">
              Increase the rate of credible community builders and advocates; We
              hope to do this with our yearly summits and quarterly workshop
              across Africa.
            </p>
            <p className="text-gray-700 mt-8">
              We hope to work with numerous organizations that value the tech
              community to raise the next community leaders and also help them
              reach their targeted audience with the help of these selfless
              community champions.
            </p>
            <p className="text-gray-700 mt-8">
              We will be creating an enabling environment for community
              builders, lovers, managers, and developer advocates to impact more
              people across Africa.
            </p>
          </div>
          <div className="md:col-span-2 col-span-1">
            <img src={groupImg} alt="group image" />
          </div>
        </div>
      )}
      {!isVisionInView && (
        <div>
          <p className="text-gray-700 mt-8">
            Our Mission - We are a diverse community of community builders,
            lovers, managers, and developer advocates collaborating on building
            and managing various tech communities to make a difference across
            Africa. We currently achieve this through the following vehicles;
            Tech Community Leads Workshop, Summit, Festival, and also through
            featured community projects. Our main goal is to create a safe place
            for selfless community builders and advocates in Africa to hang on.
          </p>
        </div>
      )}
    </>
  );
};

export default Tab;
