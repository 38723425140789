import React, { useState } from "react";
type FormData = {
  name: string;
  email: string;
  message: string;
};
type FormName = {
  "form-name": string;
};
type EncodedData = FormData & FormName;
const ContactForm = () => {
  const encode = (data: EncodedData) => {
    let objKeys = Object.keys(data) as Array<keyof EncodedData>;
    return objKeys
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
      )
      .join("&");
  };
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });
  const onInputChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setFormData({
      ...formData,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };
  const onFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    fetch("/api/contact", {
      method: "POST",
      body: encode({ "form-name": "contact", ...formData }),
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  return (
    <div>
      <h2 className="font-bold font-display text-4xl mb-5">
        Send us a message
      </h2>
      <div className="grid md:grid-cols-2 grid-cols-1 md:gap-28 gap-5 mt-5">
        <form
          className="md:grid block md:grid-cols-2 grid-cols-1 gap-5"
          name="contact"
          method="post"
          onSubmit={onFormSubmit}
          action="/api/contact"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="mt-5">
            <label htmlFor="name" className="block text-gray-600">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              required
              value={formData.name}
              className="font-display rounded-lg bg-gray-100 px-2 py-4 mt-2 w-72 focus:outline-none focus:ring-1 focus:ring-primary"
              onChange={onInputChange}
            />
          </div>
          <div className="mt-5">
            <label htmlFor="email" className="block text-gray-600">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              required
              className="font-display rounded-lg bg-gray-100 px-2 py-4 mt-2 w-72 focus:outline-none focus:ring-1 focus:ring-primary"
              onChange={onInputChange}
              value={formData.email}
            />
          </div>
          <div className="mt-5 col-span-2">
            <label htmlFor="message" className="block text-gray-600">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              cols={73}
              rows={5}
              required
              className="font-display rounded-lg bg-gray-100 px-2 py-4 mt-2 w-full focus:outline-none focus:ring-1 focus:ring-primary"
              value={formData.message}
              onChange={onInputChange}
            />
          </div>
          <div className="mt-5 col-span-2">
            <button
              type="submit"
              className="bg-primary text-white px-14 py-4 rounded-lg"
            >
              Submit
            </button>
          </div>
        </form>
        <div>
          <div className="grid md:grid-cols-12 grid-cols-1 md:gap-10 gap-2">
            <div className="md:col-span-2 col-span-1">
              <div className="border-l-2 border-gray-200  h-80 md:block hidden" />
            </div>
            <div className="md:col-span-10 col-span-1">
              <div className="mt-5">
                <label htmlFor="contactemail" className="block text-gray-600">
                  Email
                </label>
                <h4 className="font-bold text-xl mt-2">
                  hi@communityleads.africa
                </h4>
              </div>
              <div className="mt-10">
                <label htmlFor="address" className="block text-gray-600">
                  Address
                </label>
                <h4 className="font-bold text-xl mt-2">Africa</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
