import React from "react";
import imgAbout from "images/img-about.png";
import ContactForm from "components/ContactForm";
import Layout from "components/Layout";
import Seo from "components/Seo";
import Tab from "components/Tab";

const About = (): JSX.Element => (
  <Layout>
    <Seo
      title="About Us"
      description="We are a community for community builders, managers and developer advocates within and across Africa with the sole aim of increasing the rate of credible community builders and advocates, while also causing collaboration and sharing of resources among them"
    />
    <section>
      <div className="container max-w-screen-xl mx-auto py-20 px-6">
        <div className=" grid md:grid-cols-5 grid-cols-1 md:gap-28 gap-8 ">
          <div className="col-span-3">
            <h2 className="font-bold text-2xl">About Us</h2>
            <h1 className="leading-tight font-bold text-6xl mt-3 md:w-3/5 w-full">
              What We Are Really Doing.
            </h1>
            <p className="mt-6 text-gray-700">
              Tech Community Leads Africa idea was birthed late 2019 after
              series of issues we faced when building and managing our local
              tech communities and the lack of resources and tools for community
              builders, managers, and advocates to successfully build and manage
              a community.
            </p>
            <p className="mt-6 text-gray-700">
              We are a community for community builders, managers and developer
              advocates within and across Africa with the sole aim of increasing
              the rate of credible community builders and advocates, while also
              causing collaboration and sharing of resources among them
            </p>
          </div>
          <div className="col-span-2">
            <img
              src={imgAbout}
              alt="About Us Image"
              className="rounded-tl-lg"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container max-w-screen-xl mx-auto py-20 px-4">
        <Tab />
      </div>
    </section>
    <section>
      <div className="container max-w-screen-xl mx-auto py-20 px-4">
        <ContactForm />
      </div>
    </section>
  </Layout>
);

export default About;
